<template>
    <div class="container m-3">
        <h2>Sandálias</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                :key="p.id"
                :title="p.name"
                :img-src="
                    require(`../../assets/images/produtos/sandalias/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Sandalia",
    data() {
        return {
            products: [
                {
                    id: 1,
                    name: "Sandália Ergonofeet - Preta",
                    image: "sandalia-ergonofoot.png",
                    active: true,
                },
                {
                    id: 2,
                    name: "Sandália Fly Feet - Branca",
                    image: "sandalia-fly-feet-branca.jpeg",
                    active: true,
                },
                {
                    id: 3,
                    name: "Sandália Fly Feet - Rosa",
                    image: "sandalia-fly-feet-rosa.jpeg",
                    active: true,
                },
                {
                    id: 4,
                    name: "Sandália Fly Feet - Amarela",
                    image: "sandalia-fly-feet-amarela.jpeg",
                    active: true,
                },
                {
                    id: 5,
                    name: "Sandália Fly Feet - Bege",
                    image: "sandalia-fly-feet-bege.jpeg",
                    active: true,
                },
                {
                    id: 6,
                    name: "Sandália Fly Feet - Nuvem City",
                    image: "fly-feet-nuvem-city.jpeg",
                    active: true,
                },
                {
                    id: 7,
                    name: "Sapato Nuvem",
                    image: "sapato-nuvem.jpeg",
                    active: true,
                },
                {
                    id: 8,
                    name: "Sticky Shoes",
                    image: "sticky-shoes.jpeg",
                    active: true,
                },
                {
                    id: 9,
                    name: "Doctor Flex",
                    image: "doctor_flex.jpeg",
                    active: true,
                },
                {
                    id: 10,
                    name: "Fly Feet Work",
                    image: "fly_feet_work.jpeg",
                    active: true,
                },
                {
                    id: 11,
                    name: "Total Support",
                    image: "total_support.jpeg",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
