<template>
    <div class="container m-3">
        <h2>Massageadores</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                :key="p.id"
                :img-src="
                    require(`../../assets/images/produtos/massageadores/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Massageadores",
    data() {
        return {
            products: [
                {
                    id: 1,
                    image: "massageador-1.jpeg",
                    active: true,
                },
                {
                    id: 2,
                    image: "massageador-2.jpeg",
                    active: true,
                },
                {
                    id: 3,
                    image: "massageador-3.jpeg",
                    active: true,
                },
                {
                    id: 4,
                    image: "massageador-4.jpeg",
                    active: true,
                },
                {
                    id: 5,
                    image: "massageador-5.jpeg",
                    active: true,
                },
                {
                    id: 6,
                    image: "massageador-6.jpeg",
                    active: true,
                },
                {
                    id: 7,
                    image: "massageador-7.jpeg",
                    active: true,
                },
                {
                    id: 8,
                    image: "massageador-8.jpeg",
                    active: true,
                },
                {
                    id: 9,
                    image: "massageador-9.jpeg",
                    active: true,
                },
                {
                    id: 10,
                    image: "massageador-10.jpeg",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
