<template>
    <div class="container m-3">
        <h2>Fitness</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                :key="p.id"
                :img-src="
                    require(`../../assets/images/produtos/fitness/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Fitness",
    data() {
        return {
            products: [
                {
                    id: 1,
                    image: "fitness-1.jpeg",
                    active: true,
                },
                {
                    id: 2,
                    image: "fitness-2.jpeg",
                    active: true,
                },
                {
                    id: 3,
                    image: "fitness-3.png",
                    active: true,
                },
                {
                    id: 4,
                    image: "fitness-4.png",
                    active: true,
                },
                {
                    id: 5,
                    image: "fitness-5.jpeg",
                    active: true,
                },
                {
                    id: 6,
                    image: "fitness-6.jpeg",
                    active: true,
                },
                {
                    id: 7,
                    image: "fitness-7.jpeg",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
