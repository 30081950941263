<template>
    <div class="container m-3">
        <h2>Uso Doméstico</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                :key="p.id"
                :img-src="
                    require(`../../assets/images/produtos/domestico/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Domestico",
    data() {
        return {
            products: [
                {
                    id: 1,
                    image: "domestico-1.jpeg",
                    active: true,
                },
                {
                    id: 2,
                    image: "domestico-2.png",
                    active: true,
                },
                {
                    id: 3,
                    image: "domestico-3.png",
                    active: true,
                },
                {
                    id: 4,
                    image: "domestico-4.png",
                    active: true,
                },
                {
                    id: 5,
                    image: "domestico-5.png",
                    active: true,
                },
                {
                    id: 6,
                    image: "domestico-6.png",
                    active: true,
                },
                {
                    id: 7,
                    image: "domestico-7.jpeg",
                    active: true,
                },
                {
                    id: 8,
                    image: "domestico-8.jpeg",
                    active: true,
                },
                {
                    id: 9,
                    image: "domestico-9.jpeg",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
