<template>
    <div class="container m-3">
        <h2>Travesseiros</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                v-show="p.active"
                :key="p.id"
                :img-src="
                    require(`../../assets/images/produtos/travesseiros/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Travesseiros",
    data() {
        return {
            products: [
                {
                    id: 1,
                    image: "img-1.jpeg",
                    active: true,
                },
                {
                    id: 2,
                    image: "img-2.jpeg",
                    active: true,
                },
                {
                    id: 3,
                    image: "img-3.jpeg",
                    active: false,
                },
                {
                    id: 4,
                    image: "img-4.jpeg",
                    active: true,
                },
                {
                    id: 5,
                    image: "img-5.jpeg",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
