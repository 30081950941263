<template>
    <div class="container m-3">
        <h2>Saúde e Bem Estar</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                :key="p.id"
                :img-src="
                    require(`../../assets/images/produtos/saude/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >

                <b-collapse v-if="p.description.length > 0" :id="`collapse-${p.id}`">
                    <b-card>
                        <b-card-text v-html="p.description">
                        </b-card-text>    
                    </b-card>
                </b-collapse>


                <b-button v-b-toggle="`collapse-${p.id}`" class="mb-1 mt-2" variant="info">Mais Informações</b-button>
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Saude",
    data() {
        return {
            products: [
                {
                    id: 1,
                    name: "LEGLINE 15-23 mmHg / 20-30 mmHg",
                    description: `<p>As meias <b>Legline</b> da Venosan® Brasil são feitas para mulheres dinâmicas que se previnem sem abrir mão do conforto, bem estar e elegância. Possui tecnologia têxtil avançada, garantindo a terapia elasto-compressiva graduada, transparência, maciez e conforto, atendendo as exigências médicas e estéticas. A combinação perfeita entre saúde e beleza para quem deseja pernas bonitas, aliviadas e saudáveis.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>15 – 23 mmHg: Edema leve, Pernas cansadas, Trabalho em pé, Gravidez, Cirurgia venosa, Viagens</li>
                        <li>20 – 30 mmHg: Edema discreto, Varicosidade leve, Prevenção de varicosidade durante a gravidez</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        <li>AGH: Meia coxa com silicone</li>
                        <li>ATM: Meia calça gestante</li>
                        </ul>`,
                    image: "legline1523.jpg",
                    active: true,
                },
                {
                    id: 2,
                    name: "COMFORTLINE 20-30 mmHg / 30-40mmHg",
                    description: `<p>A <strong>Comfortline</strong> é a linha tradicional de meias compressivas da Venosan® Brasil. A combinação perfeita entre conforto e naturalidade, proporcionando agradável sensação ao calçar. Produzidas com tecnologia sofisticada e avançada, a partir de elastano com duplo recobrimento de última geração. Com maior índice de rigidez, garante conforto, segurança e maior durabilidade.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>20 – 30 mmHg: Edema discreto, Varicosidade leve, Prevenção de varicosidade durante a gravidez</li>
                        <li>30 – 40 mmHg: Varizes com tendência a edema, Insuficiência venosa crônica, Pós-escleroterapia, Cirurgia de varizes</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        <li>AGH: Meia coxa com silicone</li>
                        </ul>`,
                    image: "comfortlne.jpg",
                    active: true,
                },
                {
                    id: 3,
                    name: "ULCERCOMFORT C/ ZIPER 30-45mmHg",
                    description: `<p>O Kit para o tratamento de úlcera venosa <strong>Ulcercomfort</strong> com Zíper é composto por 2 meias. Atua através de um sistema de sobreposição de 2 meias: 1 meia interna <strong>Legline Silver</strong> de compressão 10 - 15mmHg para troca diária, fabricada com fios impregnados de prata, e 1 meia externa <strong>Comfortline</strong> com Zíper de compressão 20 - 30mmHg. Sobrepostas atingem uma compressão média de 30 - 45mmHg.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>30 – 45 mmHg: Tratamento de úlceras venosas na perna, Prevenção de reincidências para úlcera venosa após fechamento, Prevenção de úlcera em caso de alterações dérmicas já existentes</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        </ul>`,
                    image: "ulcercomfortcomziper.jpg",
                    active: true,
                },
                {
                    id: 4,
                    name: "COMFORTLINE COTTON 20-30mmHg / 30-40mmHg",
                    description: `<p>A linha <strong>Comfortline Cotton</strong> possui meias e braçadeiras compressivas produzidas com 100% de algodão em contato com a pele e elastano de duplo recobrimento. Disponível também na versão Meias Compressivas <strong>Comfortline Cotton</strong> com Zíper para facilitar o calçar, trazendo mais conforto e segurança.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>20 – 30 mmHg: Edema discreto, Varicosidade leve, Prevenção de varicosidade durante a gravidez</li>
                        <li>30 – 40 mmHg: Varizes com tendência a edema, Insuficiência venosa crônica, Pós-escleroterapia, Cirurgia de varizes</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        </ul>`,
                    image: "embcomfortlinecotton.jpg",
                    active: true,
                },
                {
                    id: 5,
                    name: "ULTRALINE BRAÇADEIRA 30-40mmHg",
                    description: `<p>A Venosan®  <strong>Ultraline</strong> também em versão para braço é indicada para prevenção e tratamento de linfedema e pós-trauma.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>30 – 40 mmHg: Prevenção e tratamento de linfedema pós-mastectomia, Linfedema primário, Linfedema pós-trauma</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>BH: Braçadeira até o punho</li>
                        <li>ZH: Braçadeira até o metarcapo com polegar</li>
                        </ul>`,
                    image: "ultralinebracadeira.jpg",
                    active: true,
                },
                {
                    id: 6,
                    name: "ULTRALINE 20-30mmHg / 30-40mmHg",
                    description: `<p>A linha <strong>Ultraline 4000</strong> possui meias e braçadeiras compressivas Super Premium, em fios e malhas com efeito climático, feita com mais alto padrão de qualidade Suíço. Produzida em microfibras tactel e micro emulsão de silicone (nanotecnologia)¹, oferecendo uma agradável sensação climática. Garante maior elasticidade e maciez no seu dia a dia. As meias e braçadeiras da linha Ultraline 4000 são mais confortáveis, sedosas, duráveis e fáceis de calçar.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>20 – 30 mmHg: Edema discreto, Varicosidade leve, Prevenção de varicosidade, durante a gravidez</li>
                        <li>30 – 40 mmHg: Varizes com tendência a edema, Insuficiência venosa crônica, Pós-escleroterapia, Operação de varizes</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        <li>AGH: Meia coxa com silicone</li>
                        <li>AT: Meia calça</li>
                        <li>ATM: Meia calça gestante</li>
                        </ul>`,
                    image: "ultraline.jpg",
                    active: true,
                },
                {
                    id: 7,
                    name: "VENOSAN 6000 20-30mmHg / 30-40mmHg",
                    description: `<p>Indicada para homens e mulheres, desenhada especialmente para pessoas modernas e ativas. A <strong>Venosan® 6000</strong> entra em uma nova era de meias medicinais com alta sensibilidade e conforto. A sua textura possui a suavidade da seda, sendo elegante, fina e muito confortável, atendendo às exigências médicas e estéticas.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>20 – 30 mmHg: Edema discreto, Varicosidade leve, Prevenção de varicosidade, durante a gravidez</li>
                        <li>30 – 40 mmHg: Varizes com tendência a edema, Insuficiência venosa crônica, Pós-escleroterapia, Operação de varizes</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        <li>AGH: Meia coxa com silicone</li>
                        </ul>`,
                    image: "6000.jpg",
                    active: true,
                },
                {
                    id: 8,
                    name: "VENOSAN AES 18mmHg",
                    description: `<p>As meias <strong>Venosan® AES</strong> antiembolia e antitrombo auxiliam no aumento da velocidade do sangue na circulação venosa. Contribuem igualmente para evitar a formação de coágulos que provocam trombose e embolias pulmonares. A meia <strong>Venosan® AES</strong> também tem a versão estéril para ser usada durante a cirurgia.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>18 mmHg: Imobilidade geral, Períodos prolongados em leitos, Antes, durante e após o parto, Previne embolias(antes,durante e apóscirurgias)</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        <li>AGH: Meia coxa com silicone</li>
                        </ul>`,
                    image: "aesesteril.jpg",
                    active: true,
                },
                {
                    id: 9,
                    name: "SPORTACTIVE 20-30mmHg",
                    description: `<p>A <strong>Sportactive</strong> é uma polaina compressiva produzida com mais alto padrão de qualidade pela Venosan® Brasil. Criada especialmente para desportistas profissionais ou amadores. Confortável e com design que proporciona mais beleza e estilo no seu dia a dia. Melhora a performance diminuindo o tempo de recuperação muscular auxiliando na eliminação do ácido láctico. Aumenta o fluxo de oxigênio para o tecido muscular, beneficiando e promovendo mais eficácia no movimento, além de ajudar na recuperação pós-exercício facilitando o processo metabólico. Ajuda a combater a trombose fazendo o sangue circular, retornando para o coração de forma mais eficiente.
                        Uma perfeita combinação de tecnologia, conforto e design que otimiza a mobilidade, trazendo mais saúde, beleza  e  bem estar.</p>

                        <strong>INDICAÇÕES:</strong>
                        <ul>
                            <li>20 – 30 mmHg: Compressão e apoio para os músculos da panturrilha, Redução da vibração muscular em atividades mais intensas, facilitando o processo de recuperação metabólica, Alivia a dor e o desconforto associados à tensão muscular, Melhora a circulação sanguínea e também uma melhor eliminação do ácido láctico, Ajuda a manter a musculatura aquecida, Auxilia na recuperação pós-exercícios</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                            <li>BD: Polina</li>
                        </ul>`,
                    image: "sportactive.jpg",
                    active: true,
                },
                {
                    id: 10,
                    name: "SUPPORTLINE SOFT 18-22mmHg",
                    description: `<p>A <strong>Supportline Soft</strong> é uma meia de leve compressão com diversas cores, ideal para o dia a dia. Macia e confortável, indicada para homens e mulheres que estão em  movimento ou longo tempo na mesma posição, seja trabalhando, passeando, viajando ou praticando esportes. Diminui a fadiga e tensão nas pernas reduzindo o cansaço. Ajuda a aumentar o desempenho  esportivo  devido  a  aceleração  da  recuperação  muscular.</p>
                        <strong>INDICAÇÕES:</strong>
                        <ul>
                        <li>18 – 22 mmHg: Edema leve, Pernas cansadas, Trabalho em pé, Gravidez, Cirurgia venosa, Viagens</li>
                        </ul>

                        <strong>MODELOS:</strong>
                        <ul>
                        <li>AD: Panturrilha</li>
                        </ul>`,
                    image: "embalagemsupportlinesoft.jpg",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
