<template>
    <div id="app">
        <b-navbar
            toggleable="lg"
            type="dark"
            variant="success"
            class="text-center"
        >
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item
                        class="navbar-item"
                        v-for="r in routes"
                        :key="r.name"
                        :to="r.path"
                    >
                        {{ r.title }}
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <router-view />
    </div>
</template>

<script>
import { routes } from "./routes";
export default {
    name: "App",
    data() {
        return {
            routes,
        };
    },
};
</script>

<style scoped>
.navbar-item {
    font-size: 10pt;
}
</style>
