<template>
    <div>
        <div class="container-fluid bg-success text-center" id="contato">
            <div class="container text-white p-5">
                <h1>ENTRE EM CONTATO</h1>
            </div>

            <div class="container marketing text-white">
                <div class="row">
                    <div
                        class="col-lg-4 col-sm-12"
                        v-for="c in contacts"
                        :key="c.name"
                    >
                        <img
                            src="../../assets/icons/wpp_logo.png"
                            alt="Whatsapp Logo"
                            class="bd-placeholder-img rounded-circle"
                            width="80"
                            height="80"
                        />

                        <h2 class="fw-normal">{{ c.name }}</h2>
                        <p>
                            <a
                                :href="`https://api.whatsapp.com/send/?phone=${c.number}&text&type=phone_number&app_absent=0`"
                                target="_blank"
                                class="text-white"
                                >{{ c.phone }}</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-3 text-success">
            <div class="container">
                <h1>ENDEREÇOS</h1>
            </div>

            <div class="row" v-for="c in contacts" :key="c.name">
                <div class="col-md-5 col-sm-12">
                    <iframe
                        :src="c.map"
                        width="450"
                        height="300"
                        allowfullscreen="true"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>

                <div class="col-md-7 col-sm-12">
                    <h5>{{ c.name }}</h5>
                    <p>{{ c.address }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Contato",
    data() {
        return {
            contacts: [
                {
                    name: "Loja Duque",
                    number: "5591992569298",
                    phone: "(91)99256-9298",
                    address:
                        "Travessa Humaitá, 1764. Esquina com a Avenida Duque de Caxias com Estacionamento Gratuito no Posto Ipiranga",
                    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.566960712833!2d-48.47480445017617!3d-1.4350382989482706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92a48b2ae685751b%3A0x4701d00d0c3025de!2sAmazonmed%20Duque!5e0!3m2!1spt-BR!2sbr!4v1666921393276!5m2!1spt-BR!2sbr",
                },
                {
                    name: "Loja Marambaia",
                    number: "5591992537522",
                    phone: "(91)99253-7522",
                    address: "Rua da Mata, 114. Em frente à Feira",
                    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6241377188653!2d-48.45602225017629!3d-1.4018684989725665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92a48bc99c1723e5%3A0xfc21f1b2af4cb49!2sAmazonmed%20Marambaia!5e0!3m2!1spt-BR!2sbr!4v1666921568329!5m2!1spt-BR!2sbr",
                },
                {
                    name: "Loja Paragominas",
                    number: "5591984569336",
                    phone: "(91)98456-9336",
                    address:
                        "Fernando Guilhon, 100. Em frente à Caixa Econômica",
                    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.368505056693!2d-47.35833765017289!3d-2.995031597808064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92b75d1de5d168b3%3A0x176980c7e20af19a!2sAmazonMed%20Paragominas!5e0!3m2!1spt-BR!2sbr!4v1666921700621!5m2!1spt-BR!2sbr",
                },
            ],
        };
    },
};
</script>

<style scoped>
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}
.marketing h2 {
    font-weight: 400;
}
.marketing .col-lg-4 p {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}
</style>
