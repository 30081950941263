import Homepage from './components/pages/Homepage.vue'
import Mobilidade from './components/pages/Mobilidade.vue'
import Ortopedico from './components/pages/Ortopedico.vue'
import Massageadores from './components/pages/Massageadores.vue'
import Fitness from './components/pages/Fitness.vue'
import Hospitalar from './components/pages/Hospitalar.vue'
import UsoDomestico from './components/pages/UsoDomestico.vue'
import Sandalia from './components/pages/Sandalia.vue'
import Saude from './components/pages/Saude.vue'
import Travesseiros from './components/pages/Travesseiros.vue'
import Curativos from './components/pages/Curativos.vue'
import Contato from './components/pages/Contato.vue'

export const routes = [
    {
        path: '/',
        component: Homepage,
        name: 'home',
        title: 'Início',
    },
    {
        path: '/mobilidade',
        component: Mobilidade,
        name: 'mobilidade',
        title: "Mobilidade"
    },
    {
        path: '/ortopedicos',
        component: Ortopedico,
        name: 'ortopedicos',
        title: "Ortopédicos"
    },
    {
        path: '/massageadores',
        component: Massageadores,
        name: 'massageadores',
        title: "Massageadores"
    },
    {
        path: '/fitness',
        component: Fitness,
        name: 'fitness',
        title: "Fitness"
    },
    {
        path: '/hospitalar',
        component: Hospitalar,
        name: 'hospitalar',
        title: "Hospitalar"
    },
    {
        path: '/uso-domestico',
        component: UsoDomestico,
        name: 'uso-domestico',
        title: "Uso Doméstico"
    },
    {
        path: '/sandalias',
        component: Sandalia,
        name: 'sandalias',
        title: "Sandálias"
    },
    {
        path: '/saude-e-bem-estar',
        component: Saude,
        name: 'saude-e-bem-estar',
        title: "Saúde e Bem Estar"
    },
    {
        path: '/travesseiros',
        component: Travesseiros,
        name: 'travesseiros',
        title: "Travesseiros"
    },
    {
        path: '/curativos-e-higiene',
        component: Curativos,
        name: 'curativos-e-higiene',
        title: "Curativos e Higiene"
    },
    {
        path: '/contato',
        component: Contato,
        name: 'contato',
        title: "Contato"
    },
]