<template>
    <div class="container m-3">
        <h2>Mobilidade</h2>
        <div class="row">
            <b-card
                v-for="p in products"
                v-show="p.active"
                :key="p.id"
                :title="p.name"
                :img-src="
                    require(`../../assets/images/produtos/mobilidade/${p.image}`)
                "
                :img-alt="p.name"
                img-top
                tag="article"
                style="max-width: 20rem"
                class="col-lg-4 col-md-6 col-sm-12 m-3"
            >
            
                <b-collapse v-if="p.description.length > 0" :id="`collapse-${p.id}`">
                    <b-card>
                        <b-card-text>
                            <ul>
                                <li v-for="d in p.description" :key="d">{{ d }}</li>
                            </ul>
                        </b-card-text>    
                    </b-card>
                </b-collapse>


                <b-button v-b-toggle="`collapse-${p.id}`" class="mb-1 mt-1" variant="info">Mais Informações</b-button>
                <FaleConosco></FaleConosco>
            </b-card>
        </div>
    </div>
</template>

<script>
import FaleConosco from '../buttons/FaleConosco.vue';

export default {
    name: "Mobilidade",
    data() {
        return {
            products: [
                {
                    id: 1,
                    name: "Cadeira de Rodas Tubular Dobrável Seridó Carone",
                    description: [
                        "Suporta até 90 Kg;",
                        "Estrutura tubular (AC) 3/4″ dobrável em “X”;",
                        "Apoios (plástico PP) para os pés, individuais e articuláveis;",
                        "Apoios (plástico PP) para os braços;",
                        "Assento em tecido nylon (PA) preto;",
                        "Encosto em tecido nylon (PA) preto;",
                        "Rodas (plástico PP) 6″ com pneu maciço (PVC) direcionais atrás;",
                        "Rodas (plástico PP) 6″ com pneu maciço (PVC) na frente;",
                        "Eixos verticais direcionais (garfinhos) com deslizamento em bucha (AC);",
                        "Pintura eletrostática pó (híbrida epóxi/poliéster);",
                        "Freios bilaterais;",
                        "Laterais não removíveis;"
                    ],
                    image: "cadeira de roda serido.png",
                    active: true,
                },
                {
                    id: 2,
                    name: "Cadeira de Rodas Tubular Dobrável Angra Carone",
                    description: [
                        "Suporta até 100 Kg;",
                        "Estrutura tubular (AC) 7/8″ dobrável em “X”;",
                        "Apoios (plástico PP) para os pés, individuais, articuláveis, com ajuste de altura e removíveis;",
                        "Apoios (plástico PP) para os braços;",
                        "Assento acolchoado em tecido nylon (PA) preto;",
                        "Encosto acolchoado em tecido nylon (PA) preto, reclinável até 156 graus;",
                        "Rodas (AC) propulsoras atrás com sobre aro, pneu inflável 24″;",
                        "Rodas (plástico PP) 6″ com pneu maciço (PVC) direcionais na frente;",
                        "Eixos verticais direcionais (garfinhos) com deslizamento em rolamentos;",
                        "Pintura eletrostática pó (híbrida epóxi/poliéster);",
                        "Freios bilaterais tipo esticador;",
                        "Apoios de perna, individuais, com elevação através de pinças;",
                        "Apoios de panturrilha (PU) articulados;",
                        "Apoio de cabeça acolchoado em tecido nylon (PA), com ajuste de altura e removível;",
                        "Dispositivos antiqueda, proporcionando segurança na ação de reclinar o encosto;"
                    ],
                    image: "cadeira de roda angra.png",
                    active: true,
                },
                {
                    id: 3,
                    name: "Cadeira de Rodas Tubular Dobrável Leblon Carone",
                    description: [
                        "Suporta até 100 Kg;",
                        "Estrutura tubular (AC) 7/8″ dobrável em “X”;",
                        "Apoios (plástico PP) para os pés, individuais, articuláveis, com ajuste de altura e removíveis;",
                        "Apoios (plástico PP) para os braços;",
                        "Assento acolchoado em tecido nylon (PA) preto;",
                        "Encosto acolchoado em tecido nylon (PA) preto;",
                        "Rodas (AC) propulsoras atrás com sobre aro, pneu maciço 24″;",
                        "Rodas (plástico PP) 6″ com pneu maciço (PVC) direcionais na frente;",
                        "Eixos verticais direcionais (garfinhos) com deslizamento em rolamentos;",
                        "Pintura eletrostática pó (híbrida epóxi/poliéster);",
                        "Freios bilaterais tipo esticador;",
                        "Apoios de perna, individuais, com elevação através de pinças;",
                        "Apoios de panturrilha (PU) articulados;"
                    ],
                    image: "cadeira de roda leblon.png",
                    active: true,
                },
                {
                    id: 4,
                    name: "Cadeira de Rodas em Alumínio Dobrável D600 Dellamed",
                    description: [
                        "Suporta até 120 Kg;",
                        "Estrutura em liga de alumínio aeronáutico temperado;",
                        "Estrutura dobrável em duplo “X” com sistema de fechamento por articuladores;",
                        "Estofamento 100% em nylon impermeável acolchoado com almofada de alta densidade;",
                        "Freio bilateral;",
                        "Almofada em espuma de alta densidade;",
                        "Apoio para os pés rebatível com regulagem de altura;",
                        "Swing-away: sistema de remoção/ rebatimento rápido dos apoios de perna;",
                        "Sistema quick-release: permite a remoção fácil e rápida das rodas;",
                        "Braços escamoteáveis;",
                        "Montagem rápida;",
                        "Protetor de roupa na lateral do assento;",
                        "Porta objetos;"
                    ],
                    image: "cadeira de rodas d600.webp",
                    active: true,
                },
                {
                    id: 5,
                    name: "Cadeira de Rodas em Aço Carbono Dobrável D400 Dellamed",
                    description: [
                        "Suporta até 120 Kg;",
                        "Dobrável com sistema em duplo X em aço carbono, de fácil transporte;",
                        "Encosto rebatível (não é reclinável);",
                        "Suporte de braço almofadado;",
                        "Apoios de braços escamoteáveis;",
                        "Protetor lateral de roupas integrado;",
                        "Swing-away: sistema de remoção/ rebatimento rápido dos apoios de perna;",
                        "Sistema quick-release: permite a remoção fácil e rápida das rodas; ",
                        "Apoio de pés removíveis, rebatíveis lateralmente com regulagem de altura;",
                        "Estrutura tubular robusta e resistente de aço carbono;",
                        "Almofada com espuma de alta densidade e com capa de nylon;",
                        "Cinto abdominal de segurança;",
                        "Cinto com tiras autocolantes para apoio de panturrilha;",
                        "Porta objetos;"
                    ],
                    image: "cadeira de rodas d400.webp",
                    active: true,
                },
                {
                    id: 6,
                    name: "Cadeira de Rodas em Aço Carbono Dobrável D100 Dellamed",
                    description: [
                        "Suporta até 100 Kg;",
                        "Cadeira dobrável com sistema em duplo X em aço carbono;",
                        "Fácil transporte, cabe em qualquer porta-malas;",
                        "Estofamento em nylon acolchoado;",
                        "Apoios de braço acolchoado;",
                        "Protetor lateral de roupas;",
                        "Regulagem de altura dos apoios de pés;",
                        "Apoios articulados para os pés;",
                        "Estrutura tubular robusta e resistente de aço carbono;",
                        "Tip assist aderente: pedal de apoio para o condutor incorporado no chassi;",
                        "Cinto com tiras autocolantes para apoio de panturrilha ;",
                        "Porta objetos;",
                        "Pneu rígido antifuro;"
                    ],
                    image: "cadeira de rodas d100.webp",
                    active: true,
                },
                {
                    id: 7,
                    name: "Cadeira de Rodas Motorizada em Alumínio D1000 Dellamed",
                    description: [
                        "Suporta até 120 Kg;",
                        "Autonomia de até 30 Km;",
                        "Estrutura em liga de alumínio aeronáutico temperado;",
                        "Estrutura dobrável em duplo “X” com sistema de fechamento por articuladores;",
                        "Estofamento 100% em nylon impermeável acolchoado com almofada de alta densidade;",
                        "Freio eletromagnético inteligente;",
                        "Encosto rebatível;",
                        "Joystick controlador, ajustável para destros e canhotos;",
                        "Almofada em espuma de alta densidade incorporada ao assento;",
                        "Acompanha cinto de segurança;",
                        "Giro em 360º com 1 metro quadrado de raio de giro;",
                        "Apoio para os pés rebatível com regulagem de altura, e faixa de apoio;",
                        "Swing-away: sistema de remoção/ rebatimento rápido dos apoios de perna;",
                        "Braços escamoteáveis, com regulagem de altura;",
                        "Speed system control: regulador de velocidade máxima de até 8km/h;",
                        "Montagem rápida;",
                        "Maior potência, com 2 motores 340w e 24v;",
                        "Protetor de roupa na lateral do assento;",
                        "Porta objetos com zíper;"
                    ],
                    image: "cadeira de rodas d1000.webp",
                    active: true,
                },
                {
                    id: 8,
                    name: "Cadeira de Banho Higiênica Dobrável até 150 Kg D60 Dellamed",
                    description: [
                        "Estrutura em alumínio anti ferrugem;",
                        "Facilita a higienização do paciente sentado;",
                        "Alta resistência a umidade;",
                        "Braços escamoteáveis com sistema de engate rápido;",
                        "Assento acolchoado com frente removível;",
                        "Comadre inclusa;",
                        "Design inovador / moderno;",
                        "Apoio de braços com formato ergonômico;",
                        "A única do mercado com giro 360° sobre o eixo, facilitando a movimentação;",
                        "Apoio de pés e anti tombo rebatível;",
                        "04 rodízios blindados com travas;",
                        "Sistema 'tip assisti': auxílio para movimentação em lugares com desnível ou elevações;"
                    ],
                    image: "cadeira banho d60.webp",
                    active: true,
                },
                {
                    id: 9,
                    name: "Cadeira de Banho Higiênica Dobrável até 150 Kg D50 Dellamed",
                    description: [
                        "3 em 1: para uso no banho, sobre vaso sanitário e movimentação interna;",
                        "Totalmente dobrável e desmontável;",
                        "Design inovador / moderno;",
                        "Acompanha comadre removível;",
                        "Assento acolchoado e removível;",
                        "Apoio de braços ergonômico em polipropileno de alta resistência;",
                        "Giros de 360º: 04 rodízios blindados com travas;",
                        "Apoio de pés e anti tombo rebatível;",
                        "Encosto acolchoado;",
                        "Sistema de engate rápido por pino click;"
                    ],
                    image: "cadeira banho d50.webp",
                    active: true,
                },
                {
                    id: 10,
                    name: "Cadeira de Banho Higiênica Dobrável até 120 Kg D40 Dellamed",
                    description: [
                        "Estrutura em aço carbono;",
                        "Apoio para os braços ergonômico em plástico;",
                        "Apoio para os pés;",
                        "4 rodízios blindados com rodas giratórias de 4 polegadas e 2 travas na dianteira;",
                        "Encosto em material sintético acolchoado de alta resistência;",
                        "Totalmente dobrável e desmontável;",
                        "A única do mercado com giro 360° sobre o eixo, facilitando a movimentação;",
                        "3 em 1: para uso em banho, sobre vaso sanitário e uso com comadre; ",
                        "Possui suporte para comadre (comadre não inclusa);",
                        "Assento em polipropileno ultra resistente;"
                    ],
                    image: "cadeira banho d40.webp",
                    active: true,
                },
                {
                    id: 11,
                    name: "Cadeira de Banho Higiênica Dobrável até 100 Kg D30 Dellamed",
                    description: [
                        "Estrutura em aço carbono;",
                        "Apoio para os braços ergonômico em plástico;",
                        "Apoio para os pés;",
                        "4 rodízios blindados com rodas giratórias de 4 polegadas e 2 travas na dianteira;",
                        "Encosto em Nylon acolchoado de alta resistência;",
                        "Totalmente dobrável e desmontável;",
                        "A única do mercado com giro 360° sobre o eixo, facilitando a movimentação;",
                        "2 em 1: para uso em banho e sobre vaso sanitário;",
                        "Não possui suporte para comadre;",
                        "Assento em polipropileno ultra resistente;"
                    ],
                    image: "cadeira banho d30.webp",
                    active: true,
                },
                {
                    id: 12,
                    name: "Andador de Alumínio Dobrável 4 Rodas com Assento D12 Dellamed",
                    description: [
                        "Suporta até 100 Kg;",
                        "Estrutura em alumínio anodizado;",
                        "Freios na manopla;",
                        "8 níveis de regulagem conforme altura do usuário entre 1,55 m e 1,85 m;",
                        "Assento lavável de esponja de alta densidade;",
                        "Cesto de tecido sintético para guardar objetos (localizado embaixo do assento);",
                        "Rodas de PVC 8 polegadas;"
                    ],
                    image: "andador d12.webp",
                    active: true,
                },
                {
                    id: 13,
                    name: "Andador de Alumínio Articulado 3 em 1 D10 Dellamed",
                    description: [
                        "Suporta até 130 Kg;",
                        "Estrutura em alumínio anodizado;",
                        "Barras centrais em aço carbono;",
                        "8 níveis de regulagem conforme altura do usuário entre 1,55 m e 1,85 m;",
                        "Pés de borracha;",
                        "Apoio de mão emborrachado, macio e anatômico;"
                    ],
                    image: "andador d10.webp",
                    active: true,
                },
                {
                    id: 14,
                    name: "Muleta Canadense Fixa Mercur",
                    description: [
                        "Estrutura em alumínio anodizado fosco;",
                        "Tamanho Único;",
                        "Venda em par (2 peças);",
                        "Produto compatível com usuários que tenham altura de 1,45m a 1,90m;",
                        "Resistência de 130 kg;",
                        "Cores: Azul Escuro, Marrom Médio, Grafite, Preta e Lilás Escuro;"
                    ],
                    image: "muleta canadense fixa.webp",
                    active: true,
                },
                {
                    id: 15,
                    name: "Muleta Canadense Articulada Mercur",
                    description: [
                        "Estrutura em alumínio;",
                        "Tamanho Único;",
                        "Ajuste de altura;",
                        "Venda em par (2 peças);",
                        "Produto compatível com usuários que tenham altura de 1,50m a 2,00m;",
                        "Cor: Cinza;",
                        "Resistência de 130 kg;"
                    ],
                    image: "muleta canadense articulada.webp",
                    active: true,
                },
                {
                    id: 16,
                    name: "Muleta Auxiliar Mercur",
                    description: [
                        "Estrutura em alumínio anodizado;",
                        "Tamanhos : P (1,37m a 1,57m), M (1,57m a 1,78m) e G (1,78m a 1,98m);",
                        "Ajuste de altura;",
                        "Venda em par (2 peças);",
                        "Resistência de 130 kg;",
                        "Cor: Cinza;"
                    ],
                    image: "muleta auxiliar.webp",
                    active: true,
                },
                {
                    id: 17,
                    name: "Muleta Auxiliar Infantil Mercur",
                    description: [
                        "Estrutura em alumínio anodizado;",
                        "Produto compatível com usuários que tenham altura de 1,22m a 1,37m;",
                        "Ajuste de altura;",
                        "Venda em par (2 peças);",
                        "Resistência de 130 kg;",
                        "Cor: Cinza;"
                    ],
                    image: "muleta auxiliar infantil.webp",
                    active: true,
                },
                {
                    id: 18,
                    name: "Bengala 4 pontas com apoio Mercur",
                    description: [
                        "Suporta até 100 Kg;",
                        "Estrutura em alumínio;",
                        "Produto compatível com usuários que tenham altura de 1,50m a 2m;",
                        "Apoio de mão ergonômico;",
                        "Alça de punho;",
                        "Ponteira flexível;",
                        "Suporte para apoiar pequenos objetos quando necessário repouso ;",
                        "Cores: Azul Escuro, Grafite, Lilás Escuro, Marrom Médio e Preta;"
                    ],
                    image: "bengala 4 pontas.png",
                    active: true,
                },
                {
                    id: 19,
                    name: "Bengala dobrável Mercur",
                    description: [
                        "Suporta até 100 Kg;",
                        "Estrutura em alumínio;",
                        "Produto compatível com usuários que tenham altura de 1,65m a 1,96m;",
                        "Apoio de mão ergonômico;",
                        "Alça de punho;",
                        "Ponteira flexível;",
                        "Suporte para apoiar pequenos objetos quando necessário repouso ;",
                        "Cores: Azul Escuro, Grafite, Lilás Escuro, Marrom Médio e Preta;"
                    ],
                    image: "bengala dobrável.png",
                    active: true,
                },
            ],
        };
    },
    components: { FaleConosco }
};
</script>
